<template>
    <div class="question description column">
        <div class="label-container">
            <p>{{$t('workProviderOffer.shared.card.description.title')}}</p>
        </div>
        
        <div class="description-div" v-if="readonly" v-html="data.description.length === 0 ? 'N/A' : data.description"></div>
        <textarea v-else id="offer-description" :style="{ height: offer_description_height }" :class="['text-area', { readonly }]" v-model="data.description"></textarea>
    </div>
</template>

<script>
export default {
    name: 'OfferDescriptionComponent',

    props: {
        description: {
            type: String,
            default: ''
        },

        readonly: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        data: {
            deep: true,
            handler() {
                const self = this;
                if(self.loaded) {
                    self.$emit('update', self.data);
                    self.recalculate_offer_description_height();
                }
            }
        },

        description: function(desc) {
            const self = this;
            self.data.description = desc;
            
            self.$nextTick(() => {
                self.loaded = true;
                self.recalculate_offer_description_height();
            })
        }
    },

    data() {
        return {
            data: {
                description: ''
            },

            loaded: false,
            offer_description_height: '75px',
        }
    },

    methods: {
        recalculate_offer_description_height: function() {
            const self = this;
            const textarea = document.getElementById('offer-description');

            if(!self.data.description || self.data.description.length === 0) { self.offer_description_height = '75px'; }
            else {
                if(textarea && textarea.scrollHeight) {
                    const scroll_height = textarea.scrollHeight;

                    if(scroll_height < 75) { 
                        self.offer_description_height = '75px' 
                    } else if (scroll_height > 75 && scroll_height < 500) {
                        self.offer_description_height = `${scroll_height}px`; 
                    }else {
                        self.offer_description_height = '500px';
                    }
                }
            }
        }
    },  

    mounted() {
        const self = this;
        self.data = { description: self.description ?? 'N/A' };

        self.$nextTick(() => {
            self.loaded = true;
            self.recalculate_offer_description_height();
        })
    }
}
</script>

